@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&family=Roboto:wght@300;400;500;700;900&display=swap");

.heading-section {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 48px;
  line-height: 50px;
  letter-spacing: -3px;
  color: #ffffff;
  margin: 0 auto 80px;
  max-width: 800px;
  width: 100%;
}

.loginmain .ftco-section .logo {
  position: absolute;
  top: 0;
  right: 0;
}
.ftco-section {
  position: relative;
  padding: 0;
}
.ftco-section .logo {
  position: absolute;
  top: 0;
  left: 20px;
  max-width: 110px;
}
.logo img {
  /* filter: invert(1); */
  width: 100%;
}
.ftco-section .signin-form input.form-control {
  border: none;
  color: #bdbdbd;
  font-size: 16px;
  background-color: transparent;
  padding-left: 40px;
  padding-right: 30px;
  height: 55px;
  pointer-events: none;
  border: none;
  box-sizing: border-box;
  background: transparent;
  transition: all 0.2s linear;
  border-radius: 5px;
}
.ftco-section .signin-form .form-check-label {
  font-weight: 400;
  font-size: 16px;
  line-height: 34px;
  color: #212529;
}
.ftco-section .form-check-input {
  border: 0.5px solid #d9e1ec;
}

.ftco-section .form-check {
  padding-left: 0;
}
.form-check-input[type="checkbox"] {
  width: 27px;
  height: 27px;
  border-radius: 5px;
  margin-left: 0;
}

.ftco-section .form-check-input:checked {
  background-color: #15bd8c;
  border-color: #15bd8c;
}

.ftco-section .signin-form button.submit {
  border: 1px solid #15bd8c;
  border-radius: 5px;
  background: #15bd8c;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #fff;
  padding: 13px;
  text-decoration: none;
}

.ftco-section p a.fg-pass {
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  position: relative;
}
.ftco-section p a.fg-pass:before {
  position: absolute;
  left: 12rem;
  top: 10px;
  height: 1px;
  background: #dae0f2;
  width: 260px;
  content: "";
}

.ftco-section .signin-form .form-group {
  position: relative;
  border: 0.5px solid #d9e1ec;
  border-radius: 4px;
}
.ftco-section .signin-form .form-group .toggle-password {
  position: absolute;
  right: 10px;
  top: 50%;
  color: #fff;
  transform: translateY(-50%);
  filter: invert(0.5);
}

.ftco-section .form-group .icon {
  position: absolute;
  top: -6px;
  left: 10px;
  bottom: 0;
  width: 20px;
  height: 65px;
  background: transparent;
  font-size: 18px;
  color: #fff;
}
/* 15/03/2023 */

.login-wrap {
  padding: 0 100px;
  max-width: 370px;
  background: #fff;
  padding: 25px;
  border-radius: 5px;
  margin: 0 auto;
}
.icon img {
  filter: invert(0.5);
}
.right-im-box img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100vh;
}
@media (min-width: 1025px) {
  .h-custom-2 {
    height: 100%;
  }
}
@media (max-width: 767.98px) {
  .heading-section {
    font-size: 32px;
    line-height: 36px;
    margin: 0 auto 40px;
    max-width: 700px;
  }
  .ftco-section p a.fg-pass:before {
    width: 100px;
    content: "";
  }
  .ftco-section .signin-form input.form-control {
    font-size: 18px;
    height: 65px;
    position: relative;
  }
  .ftco-section .signin-form .form-check-label {
    font-size: 18px;
  }
  .ftco-section .signin-form button.submit {
    font-size: 22px;
  }
  .ftco-section p a.fg-pass {
    font-size: 16px;
  }
  .ftco-section p a.fg-pass:before {
    left: 11rem;
  }
  .logo a img {
    width: 110px;
  }

  .ftco-section .logo {
    top: 0;
    left: 0;
    max-width: inherit;
    position: relative;
    text-align: center;
    padding-bottom: 20px;
  }
  .right-im-box img {
    width: 100%;
    height: auto;
  }
}
.custom-form input.form-control {
  pointer-events: visible !important;
  color: #333 !important;
}

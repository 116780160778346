@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&family=Roboto:wght@300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&family=Roboto:wght@300;400;500;700;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.custom-tooltip {
    padding: 8px;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(204, 204, 204);
    white-space: nowrap;
    text-align: left;
    p {
        margin-bottom: 4px;
    }
    .label {
        text-align: center;
        font-weight: bold;
    }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: inherit;
  font-family: inherit;
  font-weight: 400;
  outline: 0 !important;
}

html {
  scroll-padding-top: 3.5rem;
  overflow-x: hidden;
}

body {
  font-size: 14px;
  background: #f5f5fa;
  font-family: "Roboto", sans-serif;
  overflow-x: hidden !important;
  position: relative;
}

a {
  color: inherit;
  cursor: pointer;
}

a:focus,
a:hover {
  color: inherit;
  text-decoration: none;
}

/* .container-fluid {
  max-width: 1920px;
} */

a,
button {
  transition: 0.5s !important;
}

ul {
  margin: 0;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  font-size: 24px;
}

header,
footer,
section {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  vertical-align: top;
  padding-top: 4.5rem;
  padding-bottom: 5rem;
}
.mob {
  display: none;
}
.desk {
  display: block;
}

section.main-wrap {
  background: #f5f5fa;
  padding: 0;
}

section.main-wrap .side-bar {
  background: #ffffff;
  border: 0.5px solid #f7f7f7;
  padding: 20px;
  margin-left: 0 !important;
  position: fixed;
  height: 100vh;
  z-index: 9;
}

section.main-wrap .side-bar .sec1 {
  padding: 20px;
  display: table;
  width: 100%;
}

section.main-wrap .side-bar .sec2 {
  border-radius: 6px;
  display: table;
  width: 100%;
}

section.main-wrap .side-bar .sec2 ul {
  padding: 90px 0 30px;
  list-style: none;
}

section.main-wrap .side-bar .sec2 .side_hading {
  padding: 17px 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #6a74a5;
}

section.main-wrap .side-bar .sec2 ul li a {
  display: table;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.0041em;
  color: #4d6276;
  text-decoration: none;
  padding: 17px 15px;
  transition: all 0.5s ease-in-out;
}
section.main-wrap .side-bar .sec2 ul li.prfile a {
  margin: auto;
  width: 150px;
}
section.main-wrap .side-bar .sec2 ul li a img {
  margin-right: 10px;
  width: 20px;
  height: 20px;
  object-fit: contain;
}

section.main-wrap .side-bar .sec2 ul li a:hover {
  color: #4840c0;
  border-left: 3.5px solid #4840c0;
}
section.main-wrap .side-bar .sec2 ul li a:hover img {
  -webkit-filter: sepia(100%) hue-rotate(200deg) brightness(0);
          filter: sepia(100%) hue-rotate(200deg) brightness(0);
}
.tp-bx img {
  width: 100%;
  height: 170px;
  object-fit: cover;
}

section.main-wrap .side-bar .sec2 ul li.active a {
  color: #4840c0;
  border-left: 3.5px solid #4840c0;
}
section.main-wrap .side-bar .sec2 ul li.active a img {
  -webkit-filter: sepia(100%) hue-rotate(200deg) brightness(0);
          filter: sepia(100%) hue-rotate(200deg) brightness(0);
}
section.main-wrap .progress-bar-sec .sec1 {
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  display: table;
  width: 100%;
  padding: 15px;
  margin-bottom: 30px;
}

section.main-wrap .progress-bar-sec .sec1 h4 {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #6a74a5;
  height: 42px;
  margin-bottom: 12px;
}

section.main-wrap .progress-bar-sec .sec1 h5 {
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  color: #3a3f63;
}
section.main-wrap .progress-bar-sec .sec1 h6 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: #ff2855;
  display: inline-flex;
  align-items: center;
}
section.main-wrap .progress-bar-sec .sec1 h6.green-text {
  color: #00d0b5;
}
section.main-wrap .progress-bar-sec .sec1 h6 i {
  margin: 0 8px;
  font-size: 16px;
  color: #858ebd;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  display: inline-flex;
  align-items: center;
}

section.main-wrap .overview-cont .overview-left-area .sec2 {
  background: #ffffff;
  border-radius: 12px;
  display: table;
  width: 100%;
  padding: 25px 20px;
  margin-bottom: 20px;
}
section.main-wrap
  .overview-cont
  .overview-left-area
  .sec2
  .sec2-header
  .dropdown {
  float: right;
}
section.main-wrap
  .overview-cont
  .overview-left-area
  .sec2
  .sec2-header
  .dropdown
  button {
  border: 1px solid #aeb3cd;
  border-radius: 3px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #535875;
}
section.main-wrap
  .overview-cont
  .overview-left-area
  .sec2
  .sec2-header
  .dropdown
  ul
  li {
  display: list-item;
}
section.main-wrap .overview-cont h2.right_hading {
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  letter-spacing: 0.0041em;
  margin: 24px 0 30px;
  min-width: -webkit-max-content;
  min-width: max-content;
  margin-right: 15px;
}
section.main-wrap .overview-cont .sec2 h2 {
  font-weight: 700;
  font-size: 18px;
  line-height: 130%;
  color: #1b2124;
  margin: 0 0 20px;
  display: inline-block;
}
section.main-wrap .overview-cont .sec2 h2 span {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #3a3f63;
}
section.main-wrap .overview-cont .sec2 p.swst {
  float: right;
  display: inline-block;
  font-weight: 500;
  font-size: 10px;
  line-height: 24px;
  text-transform: uppercase;
  color: #3a3f63;
  position: relative;
}
section.main-wrap .overview-cont .sec2 p.swst:before {
  width: 6px;
  height: 6px;
  content: "";
  border-radius: 50%;
  position: absolute;
  left: -10px;
  top: 8px;
  background: #b3b3b3;
}
section.main-wrap .overview-cont .sec2 p.swst.active:before {
  background: #00d0b5;
}
section.main-wrap .overview-cont .sec2 p.swst.danger {
  margin-right: 20px;
}
section.main-wrap .overview-cont .sec2 p.swst.danger:before {
  background: #ea4335;
}

section.main-wrap .overview-cont .overview-left-area .sec2 img {
  width: 100%;
  object-fit: cover;
}

section.main-wrap .overview-cont .sec2 h2 img {
  margin-right: 15px;
}

section.main-wrap .overview-cont .sec2 ul {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  padding: 0 0 20px;
  margin: 0 0 30px;
}

.sec1.logo {
  max-width: 275px;
  position: fixed;
  top: 0;
  text-align: center;
  left: 0;
  background-color: #fff;
  z-index: 1050;
}

.overview-left-area {
  background: #dce6f0;
  min-height: 100vh;
  padding-top: 0px;
}

.overview-left-area form.search input {
  background: #ffffff;
  border: 1px solid #f5f5f6;
  box-shadow: 0px 4px 54px rgb(165 170 181 / 10%);
  border-radius: 10px;
  width: 100%;
  padding: 6px 23px;
  letter-spacing: 0.0041em;
  font-size: 16px;
  color: #191f29;
  font-weight: 300;
  line-height: 35px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHcSURBVHgBtZRRTsJAEIZndwk16kNvIDcQb1AefTDqk6mJEU4AngA9gXoCNEYb4wMSojzKDYQb9Ai8aELp7jpTFiyUQo36v3S3O/vNzO7MAvyxWNpCo9G0rXXhSGBFmgvQvaGSvYp76MNPgARa21yrSq1quGgnNjDWHYbDShp4BtjwOoW8UG84LNBcM91lkvUVUwPO+LYGcCZOmObl46Pd21QgRZbfyL8bWE9JdXbi7nXjxh46DEVY48CrNEeb0rzNFPjw9Hqpta7h0A8+gp1K5XAAKbp7bJ9jxPVFtjyKrtm0DQy4DErLYKSTo71zOkvKxlq3nPhaBMwFIvpJZ+auuMWJMN2W2bOfAIIpDSZ1HzJqxKMIScUFQOOVwdJUsygC4gH75ruVdWNOywJ9MeVBEih518wPqHwgg7gQ47OT0EoAXXfXN7dmW5tWfRXszms7oKBMY6FHzwlgFLrWZ9gJAyof76lTXwZjgjdpzDS7mq+Kmda7917K+Ao0zJQML7A8/BBC3+KiCDxXRYdObIsfUN3GoGxRBFxwghZggcZZqGu8wFNjMwNNfb5MtPt4izamRht7Sqt++BleUSdRX6vvh2QKTQVmURyKzi6oJTn8QlQdWHIlguVUeAP/oS9V3Ofv+M4D8wAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  background-position: left 15px center;
  padding-left: 50px;
  max-width: 879px;
}
.overview-left-area form.search {
  max-width: 780px;
}

section.main-wrap .overview-cont .right-side-area form.search input {
  background: #ffffff;
  border: 1px solid #f5f5f6;
  box-shadow: 0px 4px 54px rgb(165 170 181 / 10%);
  border-radius: 10px;
  width: 100%;
  padding: 12px 23px;
  letter-spacing: 0.0041em;
  font-size: 16px;
  color: #969eae;
  font-weight: 300;
  line-height: 35px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHcSURBVHgBtZRRTsJAEIZndwk16kNvIDcQb1AefTDqk6mJEU4AngA9gXoCNEYb4wMSojzKDYQb9Ai8aELp7jpTFiyUQo36v3S3O/vNzO7MAvyxWNpCo9G0rXXhSGBFmgvQvaGSvYp76MNPgARa21yrSq1quGgnNjDWHYbDShp4BtjwOoW8UG84LNBcM91lkvUVUwPO+LYGcCZOmObl46Pd21QgRZbfyL8bWE9JdXbi7nXjxh46DEVY48CrNEeb0rzNFPjw9Hqpta7h0A8+gp1K5XAAKbp7bJ9jxPVFtjyKrtm0DQy4DErLYKSTo71zOkvKxlq3nPhaBMwFIvpJZ+auuMWJMN2W2bOfAIIpDSZ1HzJqxKMIScUFQOOVwdJUsygC4gH75ruVdWNOywJ9MeVBEih518wPqHwgg7gQ47OT0EoAXXfXN7dmW5tWfRXszms7oKBMY6FHzwlgFLrWZ9gJAyof76lTXwZjgjdpzDS7mq+Kmda7917K+Ao0zJQML7A8/BBC3+KiCDxXRYdObIsfUN3GoGxRBFxwghZggcZZqGu8wFNjMwNNfb5MtPt4izamRht7Sqt++BleUSdRX6vvh2QKTQVmURyKzi6oJTn8QlQdWHIlguVUeAP/oS9V3Ofv+M4D8wAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  background-position: left 15px center;
  padding-left: 50px;
  margin: 24px 0 16px;
}
.devider {
  background: #e6f0ee;
  height: 1px;
  width: 100%;
  margin: 20px 0;
}
.module-sts ul {
  background-color: #fff;
  list-style: none;
  background: #ffffff;
  border: 1px solid #f5f5f6;
  box-shadow: 0px 4px 54px rgb(165 170 181 / 10%);
  border-radius: 6px;
  padding: 0;
}
.module-sts ul li {
  padding: 5px 20px 5px 10px;
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  color: #0f1222;
  box-shadow: inset 0px -0.5px 0px rgb(0 0 0 / 20%);
}
.module-sts ul li span {
  border-left: 3px solid #bfc3ce;
  padding-left: 10px;
  border-radius: 3px;
}
.module-sts ul li span.red-border {
  border-left: 3px solid #ff2855;
}
.module-sts ul li span.green-border {
  border-left: 3px solid #15bd8c;
}
.module-sts.sec-2 ul li {
  padding: 5px 10px 5px 10px;
  color: #141a28;
  font-size: 16px;
}
.module-sts.sec-2 ul li span {
  border-left: none;
  padding-left: 0px;
}
.module-sts ul li:last-child a {
  text-align: center;
  display: block;
  text-decoration: none;
  margin: 0 auto;
  font-weight: 400;
  font-size: 12px;
  color: #535875;
}
.module-sts h3 {
  font-size: 20px;
  line-height: 140%;
  letter-spacing: 0.0041em;
  color: #203b54;
  margin-bottom: 16px;
}
.module-sts ul li:first-child {
  border-radius: 6px 6px 0px 0px;
}
.module-sts ul li:last-child {
  border-radius: 0px 0px 6px 6px;
}
.sec3.prfile {
  background: #ffffff;
  border: 0.5px solid #f7f7f7;
  border-radius: 12px;
  padding: 20px;
  margin-top: 60px;
}
.sec3.prfile h3 {
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.0041em;
  color: #203b54;
  margin-bottom: 11px;
}

.sec3.prfile span {
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.0041em;
  color: #a6b1bb;
  margin-bottom: 5px;
}
.sec3.prfile p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4d6276;
  text-decoration: underline;
  margin-bottom: 15px;
  word-break: break-all;
}

/* .sec3.prfile a {
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.0041em;
  color: #fbfbfb !important;
  background: #15bd8c;
  border-radius: 5px;
  text-decoration: none;
  padding: 5px 30px;
  display: block;
  text-align: center;
} */
.sec3.prfile .avatar {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  margin-bottom: 10px;
}
.sec3.prfile .avatar img {
  width: 44px;
  height: 44px;
  border-radius: 100%;
  object-fit: cover;
}

section.main-wrap .overview-cont .sec2 ul li {
  width: 32.33%;
  display: inline-block;
  margin: 0 0 10px;
}

section.main-wrap .overview-cont .sec2 ul li label {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
}

section.main-wrap .overview-cont .sec2 ul li input[type="checkbox"] {
  margin-right: 17px;
}

section.main-wrap .overview-cont .sec2 ul li input[type="checkbox"]:after {
  line-height: 1.5em;
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 2px solid #a8abad;
  border-radius: 4px;
  background: #2b2b4b;
}

section.main-wrap
  .overview-cont
  .sec2
  ul
  li
  input[type="checkbox"]:checked:after {
  border: 2px solid #d8261c;
  background: red;
  box-shadow: inset 0 0 0 2px #2b2b4b;
}

.position-relative {
  position: relative !important;
  display: inline-block;
  width: auto;
}
.sec3.prfile .position-relative span {
  position: absolute;
  width: 16px;
  height: 16px;
  background: #ff2855;
  font-size: 12px;
  border-radius: 50%;
  color: #fff;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  left: 21px;
  top: -3px;
}

.side-bar {
  transition: 0.6s;
}
.side-bar .navbar-collapse .h-close {
  display: none;
}
.side-bar {
  width: 275px !important;
  display: block;
}

.side-bar .nav-item .nav-link {
  position: relative;
}

/* custom scrollbar */
.navbar-expand-lg .navbar-nav::-webkit-scrollbar {
  width: 20px;
}

.side-bar::-webkit-scrollbar-track {
  background-color: transparent;
}

.navbar-expand-lg .navbar-nav::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

.navbar-expand-lg .navbar-nav::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.side-bar .nav-item .nav-link .img-profile {
  height: 2rem;
  width: 2rem;
}

.side-bar.toggled {
  overflow: visible;
  width: 6.5rem !important;
}
.wrapper {
  display: flex;
}

.main-wrap .navbar-expand-lg .navbar-nav {
  flex-direction: column;
  height: 100vh;
  overflow-y: auto;
}
.overview-cont {
  width: 100%;
  overflow-x: hidden;
  padding: 0 12px;
  margin-left: 275px;
}
.side-bar .sec1 {
  text-align: center;
}
.side-bar .sec1 img {
  width: auto;
}

/********about-page**********/

.abt-top-pic {
  width: 100%;
  position: relative;
  margin-bottom: 75px;
}
.abt-top-pic img {
  width: 100%;
  height: 250px !important;
  object-fit: cover;
}

.green-box {
  background: #15bd8c;
  border-radius: 15px;
  width: 80px;
  height: 80px;
  position: absolute;
  top: 30px;
  left: 40px;
}
.abt-top-pic img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.devider2 {
  border: 1px solid #eaedf7;
  box-shadow: 0px 2px 4px rgba(28, 41, 90, 0.0367952);
}

.cms-content h5 {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #636e95;
  margin: 20px 0;
}
.cms-content p {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #242f57;
  margin-bottom: 35px;
}
.cms-content p:last-child {
  margin-bottom: 0px;
}
.cms-content {
  position: relative;
}

.seemore-block {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.0001) 0%,
    #ffffff 59.82%
  );
  height: 186px;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-top: 100px;
}

.seemore-block a img {
  width: 18px !important;
  margin-left: 10px;
}

.seemore-block a {
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #369afe;
  padding: 0 15px;
  text-decoration: none;
}
.video-area {
  position: relative;
  text-decoration: none;
  cursor: pointer;
  max-width: 500px;
  width: 100%;
  height: auto;
  margin: 0 auto 30px;
  display: table;
}
.video-area img {
  width: 100%;
  border-radius: 8px;
}

.video-area span.play {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 45px;
  height: 45px;
  background: #369afe;
  border-radius: 50%;
  text-align: center;
  line-height: 45px;
  color: #fff;
  font-size: 28px;
}

.dl_module,
.dl_guide {
  display: table;
  width: 100%;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  padding: 8px;
  border-radius: 12px;
  text-decoration: none;
  opacity: 0.7;
  margin: 0 auto 20px;
  max-width: 500px;
}
.dl_module {
  background-color: #15bd8c;
}
.dl_guide {
  background-color: #222653;
}
.dl_module:hover {
  opacity: 1;
  color: #fff;
}

.dl_guide:hover {
  opacity: 1;
  color: #fff;
}
.video-modal .modal-content {
  background-color: #1c1919;
}
.video-modal .modal-content .modal-header {
  border-bottom: none;
}
.video-modal .modal-content .modal-header .btn-close {
  position: absolute;
  color: #fff;
  -webkit-filter: invert(1);
          filter: invert(1);
  top: 20px;
  right: 20px;
  z-index: 999;
}

.your-module .dropdown button {
  border: 1px solid #aeb3cd;
  border-radius: 3px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #535875;
  margin-left: 15px;
}
.mlbox {
  background: #ffffff;
  box-shadow: 6px 6px 54px rgba(0, 0, 0, 0.05);
  border-radius: 14px;
  margin-bottom: 20px;
}
.mlbox img {
  width: 100%;
  border-radius: 14px 14px 0 0;
}

.mlbox .bt-bx {
  background-color: #fff;
  padding: 20px;
  border-radius: 0 0 14px 14px;
}
.mlbox .bt-bx h6 {
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: #202224;
  margin-bottom: 8px;
}
.mlbox .bt-bx p {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #5d53e4;
  margin-bottom: 5px;
}

.mlbox .bt-bx .vw-ml-btn {
  background: #5d53e4;
  border-radius: 8px;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  color: #fff;
  padding: 8px 15px;
  text-decoration: none;
  min-width: 120px;
}

.mlbox .bt-bx .vw-ml-btn:hover {
  background: #4840c0;
}

.mlbox .bt-bx .dw-ml-btn {
  background: #15bd8c;
  border-radius: 12px;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  color: #fff;
  padding: 8px 15px;
  text-decoration: none;
  border-radius: 12px;
}

.modular-table {
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
}
.modular-table table {
  min-width: 100%;
  width: -webkit-max-content;
  width: max-content;
}
.modular-table .table thead {
  background: #f7f7f7;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}
.modular-table .table thead tr th {
  padding: 16px 40px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #203b54;
  text-align: center;
}
.modular-table .table thead tr th:first-child {
  text-align: left;
}
.modular-table .table tbody td:first-child {
  text-align: left;
}
.modular-table .table tbody td {
  background: #ffffff;
  border-radius: 3px;
  padding: 16px 40px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #0f1222;
  text-align: center;
}
.modular-table .table tbody tr td a.incomplete {
  border: 1px solid #e46d14;
  border-radius: 3px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #e46d14;
  padding: 7px 10px;
  text-decoration: none;
  height: 33px;
  line-height: 33px;
  display: inline-table;
  width: 135px;
}

.modular-table .table tbody tr td a.activate {
  border: 1px solid #00aa5a;
  border-radius: 3px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.0041em;
  text-align: center;
  color: #00aa5a;
  padding: 7px 10px;
  text-decoration: none;
  height: 33px;
  line-height: 33px;
  display: inline-table;
  width: 135px;
}
.modular-table .statusText label.inactive {
  border: 1px solid #e46d14;
  border-radius: 3px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.0041em;
  text-align: center;
  color: #e46d14;
  padding: 2px 7px;
  text-decoration: none;
  height: 33px;
  line-height: 33px;
  display: inline-table;
  width: 135px;
}
.modular-table .statusText label.active {
  border: 1px solid #00aa5a;
  border-radius: 3px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.0041em;
  text-align: center;
  color: #00aa5a;
  padding: 2px 7px;
  text-decoration: none;
  height: 33px;
  line-height: 33px;
  display: inline-table;
  width: 135px;
}
/* .modular-table.big-table table {
  min-width: 1160px;
} */

.overview-left-area a.adcm-btn {
  border: 1px solid #15bd8c;
  border-radius: 3px;
  background: #15bd8c;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #fff;
  text-decoration: none;
  min-width: 130px;
  letter-spacing: 0.0041em;
  display: inline-block;
  height: 40px;
  line-height: 40px;
}
.overview-left-area a.adcm-btn:hover {
  background: #0fa87c;
}

.overview-left-area a.adcm-btn svg path {
  fill: white !important;
  stroke: white !important;
}

.overview-left-area a.third-btn,
.third-btn {
  border: 1px solid #333;
  background: #333;
}
.overview-left-area a.third-btn:hover,
.third-btn:hover {
  background: #101010;
}
.overview-left-area a.adcm-btn.Cancel {
  background: #fff;
  border: #fff;
  letter-spacing: 0.0041em;
  color: #3a3f63;
  border: 1px solid #ccc;
}
.modular-table .table tbody tr td a.Completed {
  border: 1px solid #15bd8c;
  border-radius: 3px;
  background: #15bd8c;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #fff;
  padding: 7px 10px;
  letter-spacing: 0.0041em;
  text-decoration: none;
  height: 33px;
  line-height: 33px;
  display: inline-table;
  width: 135px;
}
.modular-table .table tbody tr td button.Modify-btn {
  border: 1px solid #15bd8c;
  border-radius: 3px;
  background: #15bd8c;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #fff;
  padding: 9px 12px;
  text-decoration: none;
}
.seemore-block a img {
  min-height: auto !important;
}

/*----------login----------*/

/* .loginmain {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
}
.loginmain .ftco-section {
  padding: 10rem 0;
} */
/* .heading-section {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 48px;
  line-height: 50px;
  letter-spacing: -3px;
  color: #ffffff;
  margin: 0 auto 80px;
  max-width: 800px;
  width: 100%;
}

.loginmain .logo {
  position: absolute;
  top: 0;
  right: 0;
}
.ftco-section {
  position: relative;
  padding: 0;
  height : 100vh;
}
.ftco-section .signin-form input.form-control {
  border: none;
  color: #fff;
  font-size: 22px;
  background-color: transparent;
  padding-left: 40px;
  padding-right: 30px;
  height: 65px;
}
.ftco-section .signin-form .form-check-label {
  font-weight: 400;
  font-size: 23px;
  line-height: 34px;
  color: #ffffff;
}
.ftco-section .form-check {
  padding-left: 0;
}
.form-check-input[type="checkbox"] {
  width: 27px;
  height: 27px;
  border-radius: 4px;
  margin-left: 0;
}

.ftco-section .form-check-input:checked {
  background-color: #fff;
  border-color: #fff;
}

.ftco-section .signin-form button.submit {
  background: #f3ba2f;
  border-radius: 27px;
  color: #fff;
  padding: 15px;
  font-weight: 700;
  font-size: 26px;
  line-height: 24px;
}
.form-check-input:checked[type="checkbox"] {
  background-image: url(../../images/check-solid.svg);
}
.ftco-section p a.fg-pass {
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  position: relative;
}
.ftco-section p a.fg-pass:before {
  position: absolute;
  left: 12rem;
  top: 10px;
  height: 1px;
  background: #dae0f2;
  width: 260px;
  content: "";
}

.ftco-section .signin-form .form-group {
  position: relative;
  border: 0.5px solid #d9e1ec;
  border-radius: 4px;
}
.ftco-section .signin-form .form-group .toggle-password {
  position: absolute;
  right: 10px;
  top: 50%;
  color: #fff;
  transform: translateY(-50%);
}
.ftco-section .form-group .icon {
  position: absolute;
  top: 0;
  left: 7px;
  bottom: 0;
  width: 20px;
  height: 65px;
  background: transparent;
  font-size: 18px;
  color: #fff;
}
.icon img {
  filter: invert(0.5);
}
body {
  margin: 0;
} */
/*=====profile upload=========*/

.company-main .company-profile {
  /* min-height: 125px; */
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  border-radius: 12px 12px 0px 0px;
}

.company-main .company-profile .profile-changer {
  background: #5d53e4;
  border-radius: 12px;
  border: 1px solid #fff;
  padding: 10px 15px;
  font-size: 12px;
  font-weight: 600;
  text-decoration: none;
  color: #fff;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  cursor: pointer;
}

.file-upload {
  display: none;
}
.circle-outer {
  position: relative;
  width: 88px;
  height: 88px;
  margin-bottom: 20px;
}
.circle {
  width: 88px;
  height: 88px;
  border-radius: 50%;
  position: relative;
  display: flex;
  /* margin-top: -144px; */
}

.p-image {
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
}

.circle img.profile-pic {
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.company-fillout {
  background-color: #fff;
  padding: 25px 40px 40px;
  margin-bottom: 30px;
  border-radius: 10px;
}

.company-fillout .fill-heaing {
  font-weight: 700;
  font-size: 16px;
  line-height: 130%;
  color: #203b54;
  margin-bottom: 20px;
}
.company-fillout .form-group {
  margin-bottom: 15px;
}
.company-fillout .form-group label {
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: #0f1222;
  margin-bottom: 10px;
}
.company-fillout .form-group input {
  background: #f6f8f9;
  border-radius: 5px;
  border: none;
  /* height: 55px; */
  /* width: 100%; */
  float: right;
  font-size: 1rem;
}
.rw-picker-caret,
.rw-picker-btn {
  color: #212529;
  background-color: #f6f8f9;
}
.company-fillout .rw-multiselect-taglist {
  background-color: #f6f8f9;
}
.form-control.rw-multiselect.rw-widget {
  padding: 0;
}
.company-fillout .rw-multiselect-tag {
  background-color: #5d53e4;
  color: #fff;
}
.company-fillout .form-group select {
  background-color: #f6f8f9;
  border-radius: 5px;
  border: none;
  /* height: 55px; */
}
.company-fillout .form-group select:focus {
  box-shadow: none;
}

.company-fillout .form-group textarea {
  background: #f6f8f9;
  border-radius: 5px;
  border: none;
  height: 110px;
  resize: none;
}
.company-fillout .form-group textarea:focus {
  box-shadow: none;
}
.company-fillout.snd
  .form-group
  .select2-container--default
  .select2-search--inline
  .select2-search__field {
  border: none;
  background-color: #f6f8f9;
  border-radius: 5px;
  border: none;
  border: none;
  height: auto;
  padding: 0.375rem 0.75rem;
}
.company-fillout.snd
  .form-group
  .select2-container--default
  .select2-selection--multiple {
  border: none;
  background-color: #f6f8f9;
}
.company-fillout.snd
  .form-group
  .select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  background-color: #15bd8c;
  border: 1px solid #15bd8c;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 5px;
  color: #fff;
  min-width: 83px;
  display: flex;
  justify-content: space-around;
}

.company-fillout.snd
  .form-group
  .select2-container--default
  .select2-selection--multiple
  .select2-selection__choice__remove {
  color: #fff;
}

.company-fillout.snd
  .form-group
  .select2-container--default.select2-container--focus
  .select2-selection--multiple {
  border: none;
  background: #f6f8f9;
  border-radius: 5px;
  border: none;
  border: none;
  height: 55px;
  padding: 0.375rem 0.75rem;
}
.company-fillout .form-group input:focus {
  box-shadow: none;
}
.company-fillout.snd {
  padding: 40px;
}
.drag-tab {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  font-size: 12px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background: #15bd8c;
  mix-blend-mode: normal;
  border-radius: 8px;
}
.drag-tab p {
  font-size: 12px;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 55px;
  margin-bottom: 0;
}
.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 55px;
  margin: 0;
  opacity: 0;
  cursor: pointer;
}
.custom-file-label {
  position: absolute;
  top: 30px;
  right: 0;
  left: 0;
  z-index: 1;
  height: 55px;
  padding: 0.375rem 0.75rem;
  line-height: 48px;
  color: #495057;
  border-radius: 5px;
  background: #f6f8f9;
  cursor: pointer;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "BROWSE";
}
.custom-file-label::after {
  position: absolute;
  top: 7px;
  right: 8px;
  bottom: 7px;
  z-index: 3;
  display: block;
  padding: 0.375rem 1.75rem;
  line-height: 29px;
  color: #fff;
  content: "BROWSE";
  background-color: #333;
  border-left: 1px solid #333;
  border-radius: 8px;
}

.company-main.add-module .company-fillout {
  background-color: #fff;
  padding: 40px;
  margin-bottom: 30px;
}

.company-fillout .form-group ul {
  list-style: none;
  padding: 0;
}
.company-fillout .form-group ul li {
  display: inline-block;
  margin-right: 15px;
}
.company-fillout .form-group ul li a.data-btn {
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: #3a3f63;
  background: rgb(242 242 242 / 70%);
  text-decoration: none;
  padding: 7px 15px;
  transition: 0.5s ease-in-out;
}
.report button.adcm-btn.search {
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  background: #15bd8c;
  text-decoration: none;
  padding: 7px 40px;
  transition: 0.5s ease-in-out;
  border: none;
  margin-top: 20px;
}
.report button.adcm-btn.export {
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  background: #15bd8c;
  text-decoration: none;
  padding: 7px 25px;
  transition: 0.5s ease-in-out;
  border: none;
}
.company-fillout .form-group ul li a.data-btn.active {
  background: #5d53e4;
  color: #fff;
}
.company-main.report .company-fillout {
  padding: 25px;
}

.company-main.report .company-fillout label.form-check-label {
  display: inline-block;
  line-height: 34px;
}

.company-main .company-fillout .form-check {
  padding-left: 0;
}

.company-main .company-fillout .form-check label.form-check-label {
  line-height: 34px;
}
.company-main .company-fillout .form-check-input:checked {
  background-color: #15bd8c;
  border-color: #15bd8c;
}
.company-main .company-fillout .form-check-input:checked[type="checkbox"] {
  background-image: url(/static/media/check-white.df420a73.png);
  background-size: 15px;
}
.search-modal .modal-content .modal-body ul {
  padding: 0;
  list-style: none;
}
.search-modal .modal-content .modal-body ul li {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  border-top: 1px solid #dcdcdc;
}
.search-modal .mainSearchBar {
  display: flex;
  align-items: center;
}
.search-modal .modal-content .modal-header {
  border-bottom: none;
  padding: 0;
}
.search-modal .modal-content .modal-header button.btn-close {
  position: absolute;
  top: 10px;
  right: 10px;
}
.search-modal .modal-content .modal-header h5.modal-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  padding: 15px 20px;
  color: #000000;
}
.search-modal .modal-content .modal-body ul li .desc span {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 6px;
  color: #000000;
}
.search-modal .modal-content .modal-body ul li {
  cursor: pointer;
}
.search-modal .modal-content .modal-body ul li .desc span.textEmail {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  display: block;
}
.search-modal .modal-body input[type="text"] {
  width: 100%;
  padding: 15px 20px;
  border: none;
  width: calc(100% - 110px);
}
.search-modal .modal-content .modal-body {
  padding: 0;
}
.search-modal .modal-content .modal-body ul li .imgbk {
  width: 40px;
  height: 40px;
  border-radius: 1.2px;
  margin-right: 15px;
}
.search-modal .modal-content .modal-body ul li .imgbk img {
  width: 100%;
  border-radius: 1.2px;
  object-fit: cover;
}
.search-modal .modal-content .modal-footer {
  padding: 7px 20px;
  justify-content: flex-start;
}
.search-modal .modal-content .modal-footer a {
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  color: #15bd8c;
  text-decoration: none;
}
.user-report .company-fillout .form-group ul {
  /* column-count: 2; */
  max-width: 100%;
}
.user-report .company-fillout .form-group ul li {
  font-weight: 700;
  font-size: 16px;
  color: #203b54;
}
.user-report .company-fillout .form-group ul li span {
  font-weight: 400;
  font-size: 16px;
  color: #203b54;
  display: inline-block;
  margin-right: 1em;
}
.user-report .company-fillout .form-group ul li {
  display: inline-block;
  margin-right: 15px;
  line-height: 35px;
}
.colostomy .nav {
  margin-bottom: 20px;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}
.colostomy .nav .nav-link {
  text-align: center;
  width: 50%;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #203b54;
  padding: 18px 0;
}
.colostomy .nav .nav-link.active {
  background: #f7f7f7;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  font-weight: 700;
  line-height: 19px;
  text-align: center;
  color: #203b54;
}
.pto {
  padding-bottom: 30px;
  margin-left: 10px;
}
.pto a {
  width: 25px;
  font-size: 14px;
  text-align: center;
  display: inline-block;
  font-weight: bold;
  text-decoration: none;
  transition: 0.5 ease-in-out;
}
.pto a:hover {
  background-color: #00aa5a;
  color: #fff !important;
  font-weight: bold;
}
.pto a.active {
  background-color: #00aa5a;
  color: #fff;
  font-weight: bold;
}

.mainSearchBar > div {
  display: block !important;
  position: relative;
}

.radioGroup {
  flex-wrap: wrap;
}

.moduleCompetencyCard input,
label {
  padding: 5px 10px;
}

.competencyDelete {
  position: absolute;
  top: -6px;
  right: -6px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #000 !important;
  border-radius: 100%;
  cursor: pointer;
}

.moduleCompetencyCard {
  background-color: #5d53e4;
  padding: 5px 7px;
  border-radius: 5px;
  margin: 5px;
  display: inline-block;
  /* width: 100%; */
  position: relative;
}

.moduleCompetencyCard label {
  color: #fff !important;
  font-weight: bold;
  margin-bottom: 0 !important;
  font-size: 16px !important;
  text-transform: capitalize;
}

.moduleCompetencyCard button {
  color: #fff !important;
  border: none;
  box-shadow: none;
  background-color: transparent;
  margin-left: 5px;
}

.modular-table button.themeColorBttn.btn.btn-secondary {
  border: 1px solid #15bd8c;
  border-radius: 3px;
  background: #15bd8c;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #fff;
  text-decoration: none;
  min-width: 130px;
  letter-spacing: 0.0041em;
  display: inline-block;
  height: 40px;
}

.overview-left-area.your-module button.adcm-btn {
  border: 1px solid #15bd8c;
  border-radius: 3px;
  background: #15bd8c;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #fff;
  text-decoration: none;
  min-width: 110px;
  letter-spacing: 0.0041em;
  display: inline-block;
  height: 40px;
}
.overview-left-area.your-module button.adcm-btn img {
  margin-right: 8px;
}
.mainSearchBar .item {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  border-top: 1px solid #dcdcdc;
  cursor: pointer;
}
.mainSearchBar .item:hover {
  background-color: #e9ecef !important;
  cursor: pointer !important;
}

.mainSearchBar div div .item {
  color: #1b2124;
}

.mainSearchBar > div > div {
  z-index: 99999999;
  color: #1b2124;
  position: absolute !important;
  max-height: 200px !important;
  left: 0 !important;
  top: 43px !important;
}

section.main-wrap .overview-cont .sec2 .dropdown > ul > li {
  width: 100% !important;
}

.sec2-header .dropdown > select > option:focus,
.sec2-header .dropdown > select > option:hover {
  color: #1e2125;
  background-color: #e9ecef;
}
.dropdown select {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding: 5px;
  color: #535875;
  min-width: 100%;
  height: 45px;
}

.dropdown select option {
  height: 20px;
}
.span#dropdownMenuButton12 {
  display: block;
  margin-bottom: 5px;
}
.sec2-header .form-group labet {
  margin-bottom: 5px;
}

.mainSearchBar div div .item img.profile-pic {
  border-radius: 1.2px;
  object-fit: cover;
  width: 100%;
}
.mainSearchBar div div .item .imgbk {
  width: 40px;
  height: 40px;
  border-radius: 1.2px;
  margin-right: 15px;
}
.mainSearchBar div div .item span {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: block;
  color: #000000;
}

.rdt_TableHead {
  border-bottom: 1px solid #6a61f6;
}

.overview-left-area a.secondary-btn,
.secondary-btn {
  border: 1px solid #6a61f6 !important;
  background: #6a61f6 !important;
  color: #fff !important;
  border-radius: 5px;
}
.overview-left-area a.secondary-btn:hover,
.secondary-btn:hover {
  background: #5d53e4;
}

.danger-btn {
  border: 1px solid #f66161;
  background: #f66161;
}
.danger-btn:hover {
  background: #c14545;
}

.primary_link {
  color: #6a61f6 !important;
  padding: 8px;
}
.primary_link svg path {
  /* fill: #6a61f6 !important; */
  stroke: #6a61f6 !important;
}

.form-control {
  padding: 0.6rem 0.75rem;
}

.overlaydpchange {
  opacity: 0;
  position: absolute;
  position: absolute;
  width: 90px;
  height: 90px;
  top: -70px;
  left: -30px;
  cursor: pointer;
}

.p-image .profile-pic {
  margin-top: 13px;
}

.modular-table table thead {
  border-bottom: 1px solid #5d53e4;
}

.recharts-default-legend {
  text-align: left !important;
  padding-left: 61px !important;
}

/* .flex2fieldForm .field-group .radioGroup{
  max-height: 150px;
 
} */

/***********Responsive**********************/
@media (min-width: 1920.98px) {
  .overview-left-area form.search {
    max-width: 880px;
    width: 100%;
    display: block;
    text-align: right;
  }
  .navbar-expand-lg .navbar-collapse {
    display: block !important;
    flex-basis: auto;
  }
  .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1860.98px) {
  .overview-left-area form.search {
    max-width: 780px !important;
    width: 100%;
    display: block;
    text-align: right;
  }
}

@media (max-width: 1920.98px) {
  .overview-left-area form.search {
    max-width: 880px;
    width: 100%;
    display: block;
    text-align: right;
  }
  .navbar-expand-lg .navbar-collapse {
    display: block !important;
    flex-basis: auto;
  }
  section.main-wrap .overview-cont .right-side-area {
    padding-left: 30px;
    padding-right: 30px;
  }
  .navbar-toggler {
    display: none;
  }
  .modular-table.big-table .table tbody td {
    padding: 16px 20px;
  }
  .modular-table.big-table .table thead tr th {
    padding: 16px 20px;
  }
}

@media (max-width: 1620.98px) {
  .overview-left-area form.search {
    max-width: 600px !important;
    width: 100%;
  }
  section.main-wrap .side-bar .sec2 ul li a {
    font-size: 15px;
  }
  section.main-wrap .overview-cont .right-side-area {
    padding-left: 20px;
    padding-right: 20px;
  }
  .mlbox .bt-bx .dw-ml-btn {
    font-size: 12px;
    padding: 8px 10px;
  }
  .mlbox .bt-bx .vw-ml-btn {
    font-size: 12px;
    padding: 8px 10px;
  }
}

section.main-wrap .overview-cont .overview-left-area .sec2 form {
  position: relative;
}
.barchart-yaxis-text {
  display: inline-block;
  position: absolute;
  bottom: 42px;
  left: 8px;
}

@media (min-width: 1441.98px) {
  section.main-wrap .overview-cont .right-side-area {
    padding-top: 25px;
  }

  section.main-wrap .overview-cont .right-side-area form.search {
    display: none;
  }

  section.main-wrap .overview-cont .right-side-area {
    padding-left: auto;
    padding-right: auto;
  }
}

@media (max-width: 1400.98px) {
  section.main-wrap .overview-cont .right-side-area {
    padding-top: 30px;
  }
  .overview-left-area form.search {
    display: none;
  }
  .overview-left-area.your-module form.search {
    display: block;
  }
  section.main-wrap .overview-cont .right-side-area form.search {
    display: block;
  }
  section.main-wrap .side-bar {
    padding: 10px;
  }
  section.main-wrap .side-bar .sec2 ul li a {
    padding: 8px 5px;
  }
  section.main-wrap .side-bar .sec2 .side_hading {
    padding: 15px 5px;
    font-size: 14px;
  }
  section.main-wrap .side-bar .sec2 ul li a {
    font-size: 14px;
    padding-left: 20px;
  }
  .overview-left-area form.search {
    max-width: 520px !important;
    width: 100%;
  }
  section.main-wrap .progress-bar-sec .sec1 h5 {
    font-size: 16px;
  }
  .heading-section {
    font-size: 40px;
    line-height: 44px;
    margin: 0 auto 50px;
    max-width: 700px;
  }
  .modular-table.big-table .table tbody tr td a.activate {
    width: 90px;
  }
  .modular-table.big-table .table tbody td {
    padding: 16px 10px;
  }
  .modular-table.big-table .table thead tr th {
    padding: 16px 10px;
  }
  .overview-left-area a.adcm-btn {
    font-size: 13px;
    min-width: 115px;
  }
}

@media (max-width: 1199.98px) {
  .sec3.prfile a {
    padding: 5px 10px;
  }

  section.main-wrap .progress-bar-sec .sec1 h5 {
    font-size: 18px;
    line-height: 26px;
    height: 50px;
  }
  .overview-left-area form.search {
    max-width: 100% !important;
    width: 100%;
  }
  .mlbox .bt-bx .dw-ml-btn {
    font-size: 14px;
    padding: 8px 15px;
  }
  .mlbox .bt-bx .vw-ml-btn {
    font-size: 14px;
    padding: 8px 15px;
  }
  .modular-table .table tbody td {
    padding: 16px 10px;
  }
  .modular-table .table thead tr th {
    padding: 16px 10px;
  }
  section.main-wrap .overview-cont .overview-left-area .sec2 img {
    min-height: 200px;
  }
  section.main-wrap .overview-cont h2.right_hading {
    font-size: 20px;
  }
}

@media (max-width: 991.98px) {
  .side-bar {
    width: 0rem !important;
    display: block;
    padding: 0 !important;
  }
  .side-bar .navbar-collapse.show {
    left: 0;
  }

  .side-bar .navbar-collapse.show ul li a {
    font-size: 18px;
    display: block;
  }

  .side-bar .navbar-collapse {
    position: fixed;
    left: -100%;
    top: 0;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    transition: 0.5s;
    display: block;
    width: 100%;
    text-align: left;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1;
  }

  .side-bar .navbar-collapse ul {
    display: inline-block;
    max-width: 220px;
    background: #fff;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    padding-top: 50px;
    padding-left: 0;
    z-index: 9999;
  }

  .side-bar .navbar-collapse .h-close {
    position: absolute;
    top: 20px;
    right: 20px;
    display: inline-block;
  }

  .navbar-toggler {
    border: none;
    box-shadow: none;
    position: relative;
    background-image: url(/static/media/hamburger-icon.b3056c33.svg);
    background-position: bottom center;
    background-size: cover;
    width: 30px;
    height: 20px;
    margin-left: 15px;
    display: block;
  }

  section.main-wrap .side-bar .sec1 {
    padding: 20px;
    display: table;
    width: auto;
    position: relative;
    z-index: 1;
  }
  section.main-wrap .side-bar .sec2 ul {
    padding: 58px 0 30px;
    list-style: none;
  }
  .overview-left-area form.search input[type="text"] {
    max-width: 100%;
  }
  .overview-left-area form.search {
    max-width: 100% !important;
    width: 100%;
  }
  .green-box {
    width: 60px;
    height: 60px;
  }
  .ftco-section p a.fg-pass:before {
    width: 202px;
  }
  .order-d {
    order: 1;
  }
  .overview-cont {
    margin-left: 0;
  }
  .sec1.logo {
    position: initial;
    z-index: initial;
  }
  svg.recharts-surface {
    width: auto !important;
    height: auto !important;
  }
  .mob-bgtop {
    background-color: #fff;
  }
}
@media (max-width: 767.98px) {
  .overview-left-area form.search {
    width: 100%;
  }
  section.main-wrap .overview-cont .overview-left-area .sec2 img {
    min-height: 180px;
  }
  .green-box {
    width: 50px;
    height: 50px;
  }
  .mlbox .bt-bx {
    padding: 15px;
  }

  .col-lg-4.col-md-5.text-end {
    text-align: left !important;
  }
  .user-report .company-fillout .form-group ul {
    max-width: 576px;
  }
  .user-report .company-fillout .form-group ul li span {
    width: auto;
  }
  .custom-tooltip p {
    margin: 0;
    font-size: 14px;
  }
}

.vedioImg img {
  width: 100%;
  border: 0;
}
.vedioImg {
  border: 0 !important;
}

@media (max-width: 575.98px) {
  .user-report .company-fillout .form-group ul {
    -webkit-column-count: unset;
            column-count: unset;
  }
  .user-report .company-fillout .form-group ul li {
    display: block;
  }
  /* .overview-left-area.your-module button.custom_search22.adcm-btn{
    height: 45px !important
  } */
  .user-report .company-fillout .form-group ul li span {
    width: 108px;
  }
  .sec2-header.d-flex.justify-content-between {
    display: block !important;
  }
  section.main-wrap
    .overview-cont
    .overview-left-area
    .sec2
    .sec2-header
    .dropdown
    button {
    margin-left: 0;
  }
  .dropdopwn-area-double {
    display: flex;
    justify-content: space-between;
  }
  section.main-wrap
    .overview-cont
    .overview-left-area
    .sec2
    .sec2-header
    .dropdopwn-area-double
    .dropdown {
    float: unset;
  }
  .colostomy .nav .nav-link {
    font-size: 14px;
  }
  .overview-left-area a.adcm-btn.Cancel {
    min-width: 70px;
  }
  .width-100percent-mob {
    width: 100%;
  }
}

@media (max-width: 379.98px) {
  .company-fillout {
    background-color: #fff;
    padding: 100px 10px 32px;
    margin-bottom: 30px;
  }
  .overview-left-area a.adcm-btn {
    font-size: 13px;
    min-width: 92px;
  }
}
.custombtnSearch {
  position: absolute !important;
  right: 0;
  top: 0;
  background-color: #5d53e4;
  color: white;
  width: 110px;
  border: none;
  outline: none;
  height: 100%;
}

.search-modal .input-group {
  border: 1px solid #9a9a9a;
  /* border-radius: var(--bs-modal-border-radius); */
}

.flex2fieldForm {
  /* display: flex; */
  width: 100%;
  justify-content: space-between;
}

.checkboxSet {
  top: -31px;
  position: relative;
}
.custom-link-text a {
  text-decoration: none !important;
  color: #15bd8c;
}
.custom-link-text a:hover {
  color: #00aa5a;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #4840c0 !important;
  color: #fff !important;
}
.nav-pills .nav-item .nav-link {
  padding: 1em 1.5em !important;
  font-weight: 500;
  font-size: 18px;
  color: #203b54 !important;
  border-radius: 15px;
}

.nav-pills .nav-link.active {
  color: #fff !important;
}
.nav-pills {
  background-color: white;
  border: none;
  border-radius: 15px;
}
.moduleReportHeading {
  font-weight: 500;
  padding: 0em 1em;
  margin-bottom: 20px;
}

.custom-no-ul {
  padding-left: 20px;
}

.custom-no-ul li {
  list-style-type: none;
  color: #000;
  padding: 5px 0;
}
.list-container {
  max-height: 300px;
  overflow-y: auto;
}

.paginationBtn {
  border-radius: 100%;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4840c0;
  color: #fff;
  margin: 0 15px;
  border: none;
  box-shadow: none;
}
.paginationBtn svg {
  -webkit-filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(12deg)
    brightness(300%) contrast(102%);
          filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(12deg)
    brightness(300%) contrast(102%);
}

.custom-no-ul li span {
  color: #4d6276;
}
.debriefinding_score {
  font-size: 28px;
  color: #4840c0;
  font-weight: bold;
}

.debriefinding-fill-heaing {
  font-weight: 700;
  font-size: 16px;
  line-height: 130%;
  color: #203b54;
  padding-bottom: 12px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(118, 94, 236, 0.1);
}

.debriefinding_innerscroll_panel {
  width: 100%;
  /* max-height: 250px; */
  min-height: 250px;
  height: calc(100vh - 450px);
  overflow-y: auto;
  overflow-x: hidden;
  display: inline-block;
  vertical-align: top;
}

.debriefinding_innerscroll_panel .itembox {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 8px;
  margin-bottom: 8px;
}
.color-purple {
  color: #4840c0 !important;
}
.mh-245 {
  min-height: 245px !important;
}

.loader-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #0b0b0b73;
  display: grid;
  place-items: center;
  z-index: 99999999999999;
}

.loader {
  border: 10px solid #e3e3e3;
  border-top: 10px solid #2ab544;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
}
.close {
  padding: 4px 8px;
  font-size: 16px;
  background: #cccccc91;
}
.link {
  cursor: pointer;
  color: #0a789f !important;
}
.filebord {
  border-bottom: 1px solid #cccccc9c;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.table-container {
  max-height: 300px; /* Adjust the maximum height as needed */
  overflow-y: auto;
}

.profile-image-circle {
  display: inline-block;
  vertical-align: middle;
}

.profile-image-circle-right {
  display: inline-block;
  width: calc(100% - 90px);
  padding-left: 20px;
  vertical-align: middle;
}

.margin-unset {
  margin-right: unset !important;
  line-height: 25px !important;
  margin-bottom: 10px;
}

.rw-widget-input {
  min-height: 45px;
  border: none;
}

.rw-widget-input input {
  height: 45px;
}

.custom-barwrapper {
  padding: 30px;
  border-radius: 10px;
  background-color: #e4f6ff;
}

.force-return-but {
  background-color: #15bd8c !important;
  color: #fff !important;
}

@media (max-width: 767.98px) {
  .profile-image-circle {
    vertical-align: top;
  }
}

.custm-btn {
  border-radius: 5px !important;
  font-size: 14px !important;
  padding: 12px 12px !important;
  height: auto !important;
  line-height: normal !important;
  display: inline-block !important;
  vertical-align: top !important;
  border: none !important;
}

.custm-btn:hover {
  -webkit-filter: brightness(0.8);
          filter: brightness(0.8);
}

.custm-success {
  background: #5d53e4 !important;
}

.custm-danger {
  background: rgb(221, 51, 51) !important;
}

.heading-section {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 48px;
  line-height: 50px;
  letter-spacing: -3px;
  color: #ffffff;
  margin: 0 auto 80px;
  max-width: 800px;
  width: 100%;
}

.loginmain .ftco-section .logo {
  position: absolute;
  top: 0;
  right: 0;
}
.ftco-section {
  position: relative;
  padding: 0;
}
.ftco-section .logo {
  position: absolute;
  top: 0;
  left: 20px;
  max-width: 110px;
}
.logo img {
  /* filter: invert(1); */
  width: 100%;
}
.ftco-section .signin-form input.form-control {
  border: none;
  color: #bdbdbd;
  font-size: 16px;
  background-color: transparent;
  padding-left: 40px;
  padding-right: 30px;
  height: 55px;
  pointer-events: none;
  border: none;
  box-sizing: border-box;
  background: transparent;
  transition: all 0.2s linear;
  border-radius: 5px;
}
.ftco-section .signin-form .form-check-label {
  font-weight: 400;
  font-size: 16px;
  line-height: 34px;
  color: #212529;
}
.ftco-section .form-check-input {
  border: 0.5px solid #d9e1ec;
}

.ftco-section .form-check {
  padding-left: 0;
}
.form-check-input[type="checkbox"] {
  width: 27px;
  height: 27px;
  border-radius: 5px;
  margin-left: 0;
}

.ftco-section .form-check-input:checked {
  background-color: #15bd8c;
  border-color: #15bd8c;
}

.ftco-section .signin-form button.submit {
  border: 1px solid #15bd8c;
  border-radius: 5px;
  background: #15bd8c;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #fff;
  padding: 13px;
  text-decoration: none;
}

.ftco-section p a.fg-pass {
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  position: relative;
}
.ftco-section p a.fg-pass:before {
  position: absolute;
  left: 12rem;
  top: 10px;
  height: 1px;
  background: #dae0f2;
  width: 260px;
  content: "";
}

.ftco-section .signin-form .form-group {
  position: relative;
  border: 0.5px solid #d9e1ec;
  border-radius: 4px;
}
.ftco-section .signin-form .form-group .toggle-password {
  position: absolute;
  right: 10px;
  top: 50%;
  color: #fff;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-filter: invert(0.5);
          filter: invert(0.5);
}

.ftco-section .form-group .icon {
  position: absolute;
  top: -6px;
  left: 10px;
  bottom: 0;
  width: 20px;
  height: 65px;
  background: transparent;
  font-size: 18px;
  color: #fff;
}
/* 15/03/2023 */

.login-wrap {
  padding: 0 100px;
  max-width: 370px;
  background: #fff;
  padding: 25px;
  border-radius: 5px;
  margin: 0 auto;
}
.icon img {
  -webkit-filter: invert(0.5);
          filter: invert(0.5);
}
.right-im-box img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100vh;
}
@media (min-width: 1025px) {
  .h-custom-2 {
    height: 100%;
  }
}
@media (max-width: 767.98px) {
  .heading-section {
    font-size: 32px;
    line-height: 36px;
    margin: 0 auto 40px;
    max-width: 700px;
  }
  .ftco-section p a.fg-pass:before {
    width: 100px;
    content: "";
  }
  .ftco-section .signin-form input.form-control {
    font-size: 18px;
    height: 65px;
    position: relative;
  }
  .ftco-section .signin-form .form-check-label {
    font-size: 18px;
  }
  .ftco-section .signin-form button.submit {
    font-size: 22px;
  }
  .ftco-section p a.fg-pass {
    font-size: 16px;
  }
  .ftco-section p a.fg-pass:before {
    left: 11rem;
  }
  .logo a img {
    width: 110px;
  }

  .ftco-section .logo {
    top: 0;
    left: 0;
    max-width: inherit;
    position: relative;
    text-align: center;
    padding-bottom: 20px;
  }
  .right-im-box img {
    width: 100%;
    height: auto;
  }
}
.custom-form input.form-control {
  pointer-events: visible !important;
  color: #333 !important;
}

.App {
  text-align: center;
}
/* 
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
a {
  text-decoration: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

